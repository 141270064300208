<template>
  <main
    class="flex bg-white md:bg-transparent rounded-md pt-4 border border-dark-100 md:p-0 md:border-0 flex-col md:flex-row items-start"
  >
    <div
      class="flex mx-4 md:mx-0 flex-row flex-wrap md:flex-col w-full md:w-52 md:items-start"
    >
      <button
        v-for="(btn, i) in pages"
        :key="i"
        @click="switchView(i)"
        :class="
          btn.active
            ? 'bg-white border-outlineGray md:rounded-l-xl md:shadow-lg text-secondary font-semibold'
            : 'text-dark-800 border-dark-100 md:border-0'
        "
        class="capitalize text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap w-fit border md:w-full"
      >
        {{ btn.title }}
      </button>
    </div>
    <div
      class="relative w-full text-sm h-auto lg:w-8/12 bg-white md:rounded-tr-none md:rounded-tr-xl md:rounded-tl-none md:rounded-xl md:border md:border-dark-50 md:shadow-md"
    >
      <span
        v-if="!fromArchived"
        @click="
          $router.push({
            name: 'EditEducationalInfo',
            params: { id: $route.params.id },
          })
        "
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/edit.svg" alt="" />
        <!-- <img class="" src="@/assets/icons/close.svg" alt="" /> -->
      </span>
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, computed, shallowRef } from "vue";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

//// View Components ////
import EducationHistoryView from "@/components/Employee/View/Education/EducationHistory.vue";
import CertificationView from "@/components/Employee/View/Education/Certification.vue";
import AwardView from "@/components/Employee/View/Education/Award.vue";

const store = useDataStore();
const { pageIndex } = storeToRefs(store);
const fromArchived = computed(() => store.getViewFromArchived);

const display = shallowRef(EducationHistoryView);

const pages = reactive([
  {
    title: "Educational History",
    component: EducationHistoryView,
    active: true,
    live: "view",
  },
  {
    title: "Certification",
    component: CertificationView,
    active: false,
    live: "view",
  },
  {
    title: "Award",
    component: AwardView,
    active: false,
    live: "view",
  },
]);

const switchView = (index) => {
  display.value = pages[index].component;

  store.$patch({
    pageIndex: index,
  });

  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};
</script>

<style></style>
